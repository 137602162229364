import { config } from '_constants';

export function trim(num) {
    if (num > 1000000) {
        return (num/1000000).toFixed(1) + 'M'
    } else if (num > 1000) {
        return (num/1000).toFixed(1) + 'K'
    } else if (num === 0) {
        return 'NA'
    } else {
        return num
    }
}

export function trimLikes(num) {
    if (num > 1000000) {
        return (num/1000000).toFixed(1) + 'M'
    } else if (num > 1000) {
        return (num/1000).toFixed(1) + 'K'
    } else if (num === 0) {
        return '0'
    } else {
        return num
    }
}

export function uniqueValues(array) {
    return Array.from(new Set(array));
 }

export function haveCommonElements(arr1, arr2) {
    if (arr1 && arr2) {
        return arr1.some(item => arr2.includes(item))
    } else {
        return false 
    }
}

export function trimProductSubscribers(num) {
    if (num > 1000000) {
        return (num/1000000).toFixed(1) + 'M'
    } else if (num > 1000) {
        return (num/1000).toFixed(1) + 'K'
    } else if (num === 0) {
        return 'New'
    } else {
        return num
    }
}

export function isEmptyStringOrWhitespace( myString ) {
    if (/\S/.test(myString)) {
        return false
    }
    return true
}

export function isEmptyHTMLString (htmlString) {
    const parser = new DOMParser();

    const { textContent } = parser.parseFromString(htmlString, "text/html").documentElement;

    return !textContent.trim();
}

export function randomString(length) {
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for(let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}

export function numberFunction(number) {
    const inc = 20170324
    const value = inc + number
    return value 
}

export function reverseNumberFunction(number) {
    const inc = 20170324
    const value = number - inc
    return value
}

export function arrayEqual(a, b) {
    if (Array.isArray(a) && Array.isArray(b) && a.length === b.length) {
      for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  export function currencyFormat(countryCode, value, maximumFractionDigits=2) {
    const l10nUSD = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: maximumFractionDigits })
    const l10nCAD = new Intl.NumberFormat("en-CA", { style: "currency", currency: "CAD", maximumFractionDigits: maximumFractionDigits })
    const l10nAUD = new Intl.NumberFormat("en-AU", { style: "currency", currency: "AUD", maximumFractionDigits: maximumFractionDigits })
    const l10nGBP = new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP", maximumFractionDigits: maximumFractionDigits })
    const l10nEUR = new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR", maximumFractionDigits: maximumFractionDigits })
    const l10nJPN = new Intl.NumberFormat("ja-JP", { style: "currency", currency: "JPY", maximumFractionDigits: maximumFractionDigits })
    const l10nIND = new Intl.NumberFormat("in-IN", { style: "currency", currency: "INR", maximumFractionDigits: maximumFractionDigits })
    const l10nITL = new Intl.NumberFormat("it-IT", { style: "currency", currency: "EUR", maximumFractionDigits: maximumFractionDigits })

    //console.log("countryCode", countryCode)
    switch(countryCode.toLowerCase()) {
        case 'us':
            return l10nUSD.format(value)
        case 'ca':
            return l10nCAD.format(value)
        case 'au':
            return l10nAUD.format(value)
        case 'gb':
            return l10nGBP.format(value)
        case 'de':
            return l10nEUR.format(value)
        case 'jp':
            return l10nJPN.format(value)
        case 'in':
            return l10nIND.format(value)
        case 'it':
            return l10nITL.format(value)
        default:
            return 'unsupported country code'
    }
  }

  export function currencyValue(currency, value, maximumFractionDigits=2) {
    const currencyUpperCase = currency.toUpperCase()
    const l10nUSD = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: maximumFractionDigits })
    const l10nCAD = new Intl.NumberFormat("en-CA", { style: "currency", currency: "CAD", maximumFractionDigits: maximumFractionDigits })
    const l10nAUD = new Intl.NumberFormat("en-AU", { style: "currency", currency: "AUD", maximumFractionDigits: maximumFractionDigits  })
    const l10nGBP = new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP", maximumFractionDigits: maximumFractionDigits  })
    const l10nEUR = new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR", maximumFractionDigits: maximumFractionDigits  })
    const l10nJPN = new Intl.NumberFormat("ja-JP", { style: "currency", currency: "JPY", maximumFractionDigits: maximumFractionDigits  })
    const l10nIND = new Intl.NumberFormat("in-IN", { style: "currency", currency: "INR", maximumFractionDigits: maximumFractionDigits  })
    // const l10nITL = new Intl.NumberFormat("it-IT", { style: "currency", currency: "EUR", maximumFractionDigits: maximumFractionDigits  })

    //console.log("countryCode", countryCode)
    switch(currencyUpperCase) {
        case 'USD':
            return l10nUSD.format(value)
        case 'CAD':
            return l10nCAD.format(value)
        case 'AUD':
            return l10nAUD.format(value)
        case 'GBP':
            return l10nGBP.format(value)
        case 'EUR':
            return l10nEUR.format(value)
        case 'JPY':
            return l10nJPN.format(value)
        case 'INR':
            return l10nIND.format(value)
        default:
            return 'unsupported country code'
    }
  }




export function getDataUri(index, url, callback) {
    var image = new Image();

    image.onload = function () {
        var canvas = document.createElement('canvas');
        canvas.width = this.naturalWidth; // or 'width' if you want a special/scaled size
        canvas.height = this.naturalHeight; // or 'height' if you want a special/scaled size

        canvas.getContext('2d').drawImage(this, 0, 0);

        // Get raw image data
        //callback(canvas.toDataURL('image/png').replace(/^data:image\/(png|jpg);base64,/, ''));

        // ... or get as Data URI
        callback(index, canvas.toDataURL(url));
    };

    image.src = url;
}

export function isValidEmail(email) {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    console.log( "emailTest", emailRegex.test(email));
    return emailRegex.test(email)
}

export function objSortIntervalAlphabetical(objArray) {
    console.log("objAray", objArray)
   return objArray.sort(function(a, b) {
    const textA = a.interval.toUpperCase();
    const textB = b.interval.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    })
}

export function objSortTierPrice(objArray) {
    
    return objArray.sort(function(a, b) {
     let minA = a.plans.reduce((min, c) => Math.min(min, c.price), a.plans[0].price)
     let minB = b.plans.reduce((min, d) => Math.min(min, d.price), b.plans[0].price)

     return (minA < minB) ? -1 : (minA > minB) ? 1 : 0;
     })
 }

export function atLeastOneLetter(text) {
    if (text.match(/[a-z]/i)) {
        return true;
    } else {
        return false;
    }
}

export function isFormattedName(text) {
    if (text.match(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u)) {
        return true;
    } else {
        return false;
    }
}

export function validUsername(text) {
    if (text.match(/^[a-zA-Z0-9_-]*$/)) {
        return true 
    } else {
        return false 
    }
}

export function onlyUniqueFeaturedCelebs(array) {
    //index 0 is the celeb Id 
    const result = array.filter( (hash => obj =>( !(hash.has(obj[0]) || (hash.add(obj[0]) && false) ) ) ) (new Set() ));

    return result 
}

export function onlyUniqueEvents(array) {
    //index 0 is the celeb Id 
    const result = array.filter( (hash => obj =>( !(hash.has(obj.event_id) || (hash.add(obj.event_id) && false) ) ) ) (new Set() ));

    return result 
}

export function hasValidPermission() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
        // console.log(user);
        let validPermission = !user.agency_as_client
        return validPermission
    } else {
        return false
    }
}

export function currencyCode(country) {
    const countryUpperCase = country.toUpperCase()
    const codes = {        
        "BD" : "bdt", "BE" : "eur", "BF" : "xof", "BG" : "bgn", "BA" : "bam",
        "BB" : "bbd", "WF" : "xpf", "BL" : "eur", "BM" : "bmd", "BN" : "bnd",
        "BO" : "bob", "BH" : "bhd", "BI" : "bif", "BJ" : "xof", "BT" : "btn",
        "JM" : "jmd", "BV" : "nok", "BW" : "bwp", "WS" : "wst", "BQ" : "usd",
        "BR" : "brl", "BS" : "bsd", "JE" : "gbp", "BY" : "byr", "BZ" : "bzd",
        "RU" : "rub", "RW" : "rwf", "RS" : "rsd", "TL" : "usd", "RE" : "eur",
        "TM" : "tmt", "TJ" : "tjs", "RO" : "ron", "TK" : "nzd", "GW" : "xof",
        "GU" : "usd", "GT" : "gtq", "GS" : "gbp", "GR" : "eur", "GQ" : "xaf",
        "GP" : "eur", "JP" : "jpy", "GY" : "gyd", "GG" : "gbp", "GF" : "eur",
        "GE" : "gel", "GD" : "xcd", "GB" : "gbp", "GA" : "xaf", "SV" : "usd",
        "GN" : "gnf", "GM" : "gmd", "GL" : "dkk", "GI" : "gip", "GH" : "ghs",
        "OM" : "omr", "TN" : "tnd", "JO" : "jod", "HR" : "hrk", "HT" : "htg",
        "HU" : "huf", "HK" : "hkd", "HN" : "hnl", "HM" : "aud", "VE" : "vef",
        "PR" : "usd", "PS" : "ils", "PW" : "usd", "PT" : "eur", "SJ" : "nok",
        "PY" : "pyg", "IQ" : "iqd", "PA" : "pab", "PF" : "xpf", "PG" : "pgk",
        "PE" : "pen", "PK" : "pkr", "PH" : "php", "PN" : "nzd", "PL" : "pln",
        "PM" : "eur", "ZM" : "zmk", "EH" : "mad", "EE" : "eur", "EG" : "egp",
        "ZA" : "zar", "EC" : "usd", "IT" : "eur", "VN" : "vnd", "SB" : "sbd",
        "ET" : "etb", "SO" : "sos", "ZW" : "zwl", "SA" : "sar", "ES" : "eur",
        "ER" : "ern", "ME" : "eur", "MD" : "mdl", "MG" : "mga", "MF" : "eur",
        "MA" : "mad", "MC" : "eur", "UZ" : "uzs", "MM" : "mmk", "ML" : "xof",
        "MO" : "mop", "MN" : "mnt", "MH" : "usd", "MK" : "mkd", "MU" : "mur",
        "MT" : "eur", "MW" : "mwk", "MV" : "mvr", "MQ" : "eur", "MP" : "usd",
        "MS" : "xcd", "MR" : "mro", "IM" : "gbp", "UG" : "ugx", "TZ" : "tzs",
        "MY" : "myr", "MX" : "mxn", "IL" : "ils", "FR" : "eur", "IO" : "usd",
        "SH" : "shp", "FI" : "eur", "FJ" : "fjd", "FK" : "fkp", "FM" : "usd",
        "FO" : "dkk", "NI" : "nio", "NL" : "eur", "NO" : "nok", "NA" : "nad",
        "VU" : "vuv", "NC" : "xpf", "NE" : "xof", "NF" : "aud", "NG" : "ngn",
        "NZ" : "nzd", "NP" : "npr", "NR" : "aud", "NU" : "nzd", "CK" : "nzd",
        "XK" : "eur", "CI" : "xof", "CH" : "chf", "CO" : "cop", "CN" : "cny",
        "CM" : "xaf", "CL" : "clp", "CC" : "aud", "CA" : "cad", "CG" : "xaf",
        "CF" : "xaf", "CD" : "cdf", "CZ" : "czk", "CY" : "eur", "CX" : "aud",
        "CR" : "crc", "CW" : "ang", "CV" : "cve", "CU" : "cup", "SZ" : "szl",
        "SY" : "syp", "SX" : "ang", "KG" : "kgs", "KE" : "kes", "SS" : "ssp",
        "SR" : "srd", "KI" : "aud", "KH" : "khr", "KN" : "xcd", "KM" : "kmf",
        "ST" : "std", "SK" : "eur", "KR" : "krw", "SI" : "eur", "KP" : "kpw",
        "KW" : "kwd", "SN" : "xof", "SM" : "eur", "SL" : "sll", "SC" : "scr",
        "KZ" : "kzt", "KY" : "kyd", "SG" : "sgd", "SE" : "sek", "SD" : "sdg",
        "DO" : "dop", "DM" : "xcd", "DJ" : "djf", "DK" : "dkk", "VG" : "usd",
        "DE" : "eur", "YE" : "yer", "DZ" : "dzd", "US" : "usd", "UY" : "uyu",
        "YT" : "eur", "UM" : "usd", "LB" : "lbp", "LC" : "xcd", "LA" : "lak",
        "TV" : "aud", "TW" : "twd", "TT" : "ttd", "TR" : "try", "LK" : "lkr",
        "LI" : "chf", "LV" : "eur", "TO" : "top", "LT" : "ltl", "LU" : "eur",
        "LR" : "lrd", "LS" : "lsl", "TH" : "thb", "TF" : "eur", "TG" : "xof",
        "TD" : "xaf", "TC" : "usd", "LY" : "lyd", "VA" : "eur", "VC" : "xcd",
        "AE" : "aed", "AD" : "eur", "AG" : "xcd", "AF" : "afn", "AI" : "xcd",
        "VI" : "usd", "IS" : "isk", "IR" : "irr", "AM" : "amd", "AL" : "all",
        "AO" : "aoa", "AQ" : "",    "AS" : "usd", "AR" : "ars", "AU" : "aud",
        "AT" : "eur", "AW" : "awg", "IN" : "inr", "AX" : "eur", "AZ" : "azn",
        "IE" : "eur", "ID" : "idr", "UA" : "uah", "QA" : "qar", "MZ" : "mzn"
    }

    // console.log("currenyCode func", country, codes[countryUpperCase].toUpperCase() )

    if (codes.hasOwnProperty(countryUpperCase)) {
        return codes[countryUpperCase].toUpperCase()
    }
}

export function capitalizeEachWord(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    // Directly return the joined string
    return splitStr.join(' '); 
}

/**
 * Define the chunk method in the prototype of an array
 * that returns an array with arrays of the given size.
 *
 * @param chunkSize {Integer} Size of every group
 */

 Object.defineProperty(Array.prototype, 'chunk', {
    value: function(chunkSize){
        var temporal = [];
        
        for (var i = 0; i < this.length; i+= chunkSize){
            temporal.push(this.slice(i,i+chunkSize));
        }
                
        return temporal;
    }
});

export function parseMediaTypeFromUrl(mediaUrl) {
    const mediaExt = mediaUrl && mediaUrl.split('.').pop()

    const isVideo = mediaExt === 'mov' || mediaExt === 'mp4' || mediaExt === 'ogg' || mediaExt === 'webm' || mediaExt === 'quicktime';
    const isPhoto = mediaExt === 'jpg' || mediaExt === 'jpeg' || mediaExt === 'png';
    const isAudio = mediaExt === 'mpeg' || mediaExt === 'mpg' || mediaExt === 'wav' || mediaExt === 'm4a' || mediaExt === 'flac'  || mediaExt === 'mp3' || mediaExt === 'wav' || mediaExt === 'aac'

    if (isVideo) {
        return "video"
    } else if (isPhoto) {
        return "image"
    } else if (isAudio) {
        return "audio"
    }

    return "" 
}

export function getStreamGalleryMediaURL(videoID) {
    const request = `/?request=stream_media&video=`
    const mediaURL = `${config.apiUrl}${request}${videoID}`
    return mediaURL 
}

export function getStreamAssetMediaURL(assetID) {
    const request = `/?request=stream_media&asset=`
    const mediaURL = `${config.apiUrl}${request}${assetID}`
    return mediaURL 
}

export function getStreamPreviewMediaURL(assetID) {
    const request = `/?request=stream_media&preview=1&asset=`
    const mediaURL = `${config.apiUrl}${request}${assetID}`
    return mediaURL 
}

export function isProductionBuild() {
    if (process.env.NODE_ENV !== 'development') {
        return true 
    } else {
        return false
    }
}

export function getCreditCardBrand(brand, useDarkDefault=false) {
    let string = useDarkDefault ? "Default_Credit_Dark" : "Default_Credit"

    if (brand === "amex" ||
        brand === "diners" ||
        brand === "discover" ||
        brand === "jcb" ||
        brand === "mastercard" ||
        brand === "paypal" ||
        brand === "unionpay" ||
        brand === "visa") 
    {
            string = brand
    } else if (brand === "unknown") {
        console.log("unknown card brand. use default case", brand)
    }
    
    return require(`assets/img/icons/cards/${brand}.png`).default 
}

export function a_an_grammarChecker(string) {
    if (string && string.length > 0) {
        const firstChar = string.charAt(0).toLowerCase()
        if (firstChar === "a" || firstChar === "e" || firstChar === "i" || firstChar === "o" || firstChar === "u" ) {
            return "an"
        } else {
            return "a"
        }
    } else {
        return "a"
    }
}


export function deg2Rad(degree) {
    return (degree * Math.PI / 180)
  }

export function rad2Deg(radian) {
    return radian * (180 / Math.PI)
}

export function isWithinRadius(lat1, lat2, lon1, lon2, 
    // _unit
) {
    // console.log("isWithinRadius - start", lat1, lat2, lon1, lon2 )
    const theta = lon1 - lon2
    // console.log("isWithinRadius - theta", theta )

    let dist = Math.sin(deg2Rad(lat1)) * Math.sin(deg2Rad(lat2)) +  Math.cos(deg2Rad(lat1)) * Math.cos(deg2Rad(lat2)) * Math.cos(deg2Rad(theta))
    // console.log("isWithinRadius - dist", dist )

    dist = Math.acos(dist)
    // console.log("isWithinRadius - dist2", dist )

    dist = rad2Deg(dist)
    // console.log("isWithinRadius - dist3", dist )
    let miles = dist * 60 * 1.1515
    // console.log("isWithinRadius - miles", miles )
    return miles

    // let unit = _unit.uppercased()

    // if (unit === "K") {
    //     console.log("isWithinRadius - K", miles * 1.609344 )
    //   return (miles * 1.609344)
    // } else if (unit === "N") {
    //     console.log("isWithinRadius - N", miles * 0.8684 )
    //   return (miles * 0.8684)
    // } else {
    //     console.log("isWithinRadius none", miles )
    //   return miles
    // }
  }

  export function capitalizeFirstLetter(str) {
    if (!str) return str; // Handle empty strings
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  