import { userConstants } from '_constants';
import { userService } from '_services';
import { alertActions } from '_actions';
import { history, isValidEmail } from '_helpers';
import { memberActions, stripeActions } from '_actions';
import { config } from '_constants';

export const userActions = {
    login,
    completeLogin,
    logout,
    registeredUser,
    resetPassword,
    facebookGetAccessToken,
    linkedInGetAccessToken,
    appleGetAccessToken,
    clearStore,
    termsAccepted,
    endSession,
    clearSession,
    getYelpData,
    setSelectedYelpData,
    selectedYelpDataMapUpdated,
    // getAll,
    // delete: _delete
};

function login(username, password, callback)  {
    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then(
                user => { 
                    if (user.success) {
                        if (user.terms_accepted) {
                            dispatch(completeLogin(user))
                        } else {
                            callback(true, "show-terms", user);
                        }
                    } else {
                        const msg = user.message ? user.message : "Unknown Server Error. Please try again"
                        const isActive = user.hasOwnProperty('active') ? user.active : null 
                        dispatch(failure(msg.toString()));
                        dispatch(alertActions.error(msg.toString()));
                        if (callback) { callback(false, msg, null, isActive) }
                    }
                },
                error => {
                    // session cannot expire without logging in                    
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function completeLogin(user) {
    return dispatch => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        user['is_user'] = !(user.is_business || user.is_specialist || user.is_agency)
        user['agency_as_client'] = false
        localStorage.setItem('user', JSON.stringify(user));
        
        dispatch(memberActions.memberQuery());  
        dispatch(stripeActions.getCustomerId());
        dispatch(success(user));
        if (user.is_business) {
            history.push('/dash/business-dashboard')
        } else if (user.is_specialist) {
            history.push('/dash/celeb-dashboard')
        } else if (user.is_agency) {
            history.push('/dash/agency-dashboard')
        } else {
            const eventId = localStorage.getItem('eventId');
            if (eventId) {
                //history.push(`${eventId}`)
                history.goBack()
                localStorage.removeItem('eventId');
            } else {
                history.push('/dash/user-dashboard')
            }
        }               
    }

    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
}

function termsAccepted() {
    const params = {
        request: 'terms_accepted',
    }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Terms Accepted", data);
                    dispatch(success());
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("termsAccepted: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };    
    
    function request()      { return { type: userConstants.TERMS_ACCEPTED_REQUEST } }
    function success()      { return { type: userConstants.TERMS_ACCEPTED_SUCCESS } }
    function failure(error) { return { type: userConstants.TERMS_ACCEPTED_FAILURE } }
}

function logout() {
    const params = {
        request: 'logout',
    }

    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Logged out", data);
                    dispatch(clearStore(true))
                    localStorage.removeItem('user');
                    dispatch(success(data));
                    
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));    
                }
            },
            error => {
                // session may expired already, do the same thing
                dispatch(clearStore(true))
                localStorage.removeItem('user');
                dispatch(success(error));
            }
    )};    
    
    function request()      { return { type: userConstants.LOGOUT_REQUEST } }
    function success(data)  { return { type: userConstants.LOGOUT_SUCCESS } }
    function failure(error) { return { type: userConstants.LOGOUT_FAILURE } }
}

function endSession(error) {
    return dispatch => {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user && user.email) {
            dispatch(alertActions.fatal(error))
        }
        //dispatch(clearStore())
        //localStorage.removeItem('user');                
    }
}

function clearSession() {
    return dispatch => {
        dispatch(clearStore())
        localStorage.removeItem('user');
        dispatch(alertActions.clear())
        dispatch(success());
        //history.push('/');
    }
    function success()  { return { type: userConstants.LOGOUT_SUCCESS } }
}

function registeredUser(email, callback = null) {
    const params = {
        request: 'registered_user',
        email: email
    }

    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("registeredUser", data)
                    dispatch(success());
                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) callback(false, msg)
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("resetPassword: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: userConstants.REGISTERED_USER_REQUEST } }
    function success()       { return { type: userConstants.REGISTERED_USER_SUCCESS } }
    function failure(error)  { return { type: userConstants.REGISTERED_USER_FAILURE, error } }
}

function resetPassword(newPassword, callback = null) {
    const params = {
        request: 'reset_password',
        new_password: newPassword,
    }

    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("resetPassword", data)
                    dispatch(success());
                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) callback(false, msg)
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("resetPassword: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: userConstants.RESET_PASSWORD_REQUEST } }
    function success()       { return { type: userConstants.RESET_PASSWORD_SUCCESS } }
    function failure(error)  { return { type: userConstants.RESET_PASSWORD_FAILURE, error } }

}


function facebookGetAccessToken(code, cmd, callback = null) {
    const params = {
        request: 'get_fb_access_token',
        code: code,
        action: cmd.action, // action can be one of 'register', 'reset-password', 'change-username'
      };
      
    console.log("facebookGetAccessToken", params, cmd)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("facebookGetAccessToken", data)
                    const authEmail = data.info.email.address
                    localStorage.setItem('auth-email', authEmail)

                    if (cmd.action === 'register') {
                        if (authEmail && isValidEmail(authEmail)) {
                            dispatch(success(data));
                            if (callback) { callback(true, data) }
                        } else {
                            const msg = "This facebook account does not have a valid email address. Please use a facebook account with a valid email address or try signing up with our other options."
                            dispatch(failure(msg));
                            dispatch(alertActions.error(msg));
                            if (callback) { callback(false, msg) }
                        }
                    } else if (cmd.action === 'change-username') {
                        dispatch(memberActions.memberQuery());        
                        // update username in localStorage
                        // const user = JSON.parse(localStorage.getItem('user'));
                        // user.email = data.info.email.address
                        // localStorage.setItem('user', JSON.stringify(user));
                        dispatch(success(data));
                        if (callback) { callback(true, data) }
                    } else {
                        dispatch(success(data));
                        if (callback) { callback(true, data) }
                    }



                    // if (data.info && data.info.hasOwnProperty('email')) {
                    //     const authEmail = data.info.email.address
                    //     localStorage.setItem('auth-email', authEmail)
                    //     if (authEmail && isValidEmail(authEmail)) {
                            
                    //     }
                    // }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("facebookGetAccessToken: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: userConstants.GET_FACEBOOK_ACCESS_TOKEN_REQUEST} }
    function success(data)   { return { type: userConstants.GET_FACEBOOK_ACCESS_TOKEN_SUCCESS, data } }
    function failure(error)  { return { type: userConstants.GET_FACEBOOK_ACCESS_TOKEN_FAILURE, error } }
}

function linkedInGetAccessToken(code, redirect_uri) {
    const params = {
        request: 'get_linkedin_access_token',
        code: code,
        redirect_uri: redirect_uri,
      };
  
    console.log("linkedInGetAccessToken", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("linkedInGetAccessToken", data)
                    dispatch(success(data));
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("linkedInGetAccessToken: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: userConstants.GET_LINKEDIN_ACCESS_TOKEN_REQUEST} }
    function success(data)   { return { type: userConstants.GET_LINKEDIN_ACCESS_TOKEN_SUCCESS, data } }
    function failure(error)  { return { type: userConstants.GET_LINKEDIN_ACCESS_TOKEN_FAILURE, error } }

}

function appleGetAccessToken(args, callback=null) {
    // if id_token is provided, then code, redicrect_uri are not used.
    //    otherwise code, redirect_uri are used to obtain id_token from 'https://appleid.apple.com/auth/token'
    // if name, email are provided, then they will be used otherwise they will be extracted from id_token
    const params = {
        request: 'get_apple_access_token',        
      };
      if (args.hasOwnProperty('code')) {
          params['code'] = args.code
      }
      if (args.hasOwnProperty('id_token')) {
        params['id_token'] = args.id_token
      }   
      if (args.hasOwnProperty('redirect_uri')) {
          params['redirect_uri'] = args.redirect_uri
      }
      if (args.hasOwnProperty('name')) {
          params['name'] = args.name
      }
      if (args.hasOwnProperty('emai')) {
          params['email'] = args.email
      }
  
    console.log("appleGetAccessToken", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("appleGetAccessToken", data)
                    dispatch(success(data));
                    if (callback) { callback(true, data) }

                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }

                }
            },
            error => {
                if (error.status === 401) {
                    console.log("appleGetAccessToken: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: userConstants.GET_APPLE_ACCESS_TOKEN_REQUEST} }
    function success(data)   { return { type: userConstants.GET_APPLE_ACCESS_TOKEN_SUCCESS, data } }
    function failure(error)  { return { type: userConstants.GET_APPLE_ACCESS_TOKEN_FAILURE, error } }

}

function getYelpData(phone, callback) {
    const params = {
        request: 'get_yelp_data',
        phone: phone,
      };
  
    console.log("getYelpData", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("gotYelpData", data)
                    dispatch(success(data));
                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    
                    if (callback) {
                        callback(false, msg)
                    }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getYelpData: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: userConstants.GET_YELP_DATA_REQUEST} }
    function success(data)   { return { type: userConstants.GET_YELP_DATA_SUCCESS, data } }
    function failure(error)  { return { type: userConstants.GET_YELP_DATA_FAILURE, error } }
}

function setSelectedYelpData(yelpData) {
    return dispatch => {
        dispatch(update(yelpData))
    }
    function update(yelpData) { return { type: userConstants.SET_SELECTED_YELP_DATA, yelpData }}
}

function selectedYelpDataMapUpdated(callback=null) {
    return dispatch => {
        dispatch(update())
        if (callback) {
            callback()
        }
    }
    function update() { return { type: userConstants.SELECTED_YELP_DATA_MAP_UPDATED}}
}


function clearStore(redirectToHome=false) {
    return dispatch => {
        if (redirectToHome) {
            dispatch(logoutClearStore())
            window.open(config.serverUrl, "_self")
            // history.push('/')

        } else {
            dispatch(clearReduxStore())
        }
    }
    function clearReduxStore() { return { type: userConstants.CLEAR_REDUX_STORE }}
    function logoutClearStore() { return { type: userConstants.LOGOUT_CLEAR_STORE }}

}
// function getAll() {
//     return dispatch => {
//         dispatch(request());

//         userService.getAll()
//             .then(
//                 users => dispatch(success(users)),
//                 error => dispatch(failure(error.toString()))
//             );
//     };

//     function request() { return { type: userConstants.GETALL_REQUEST } }
//     function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
//     function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
// }

// // prefixed function name with underscore because delete is a reserved word in javascript
// function _delete(id) {
//     return dispatch => {
//         dispatch(request(id));

//         userService.delete(id)
//             .then(
//                 user => dispatch(success(id)),
//                 error => dispatch(failure(id, error.toString()))
//             );
//     };

//     function request(id) { return { type: userConstants.DELETE_REQUEST, id } }
//     function success(id) { return { type: userConstants.DELETE_SUCCESS, id } }
//     function failure(id, error) { return { type: userConstants.DELETE_FAILURE, id, error } }
// }