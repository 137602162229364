export const notificationConstants = {
    GET_BID_NOTIFICATIONS_REQUEST: 'GET_BID_NOTIFICATIONS_REQUEST',
    GET_BID_NOTIFICATIONS_SUCCESS: 'GET_BID_NOTIFICATIONS_SUCCESS',
    GET_BID_NOTIFICATIONS_FAILURE: 'GET_BID_NOTIFICATIONS_FAILURE',

    GET_SCHREQUEST_NOTIFICATIONS_REQUEST: 'GET_SCHREQUEST_NOTIFICATIONS_REQUEST',
    GET_SCHREQUEST_NOTIFICATIONS_SUCCESS: 'GET_SCHREQUEST_NOTIFICATIONS_SUCCESS',
    GET_SCHREQUEST_NOTIFICATIONS_FAILURE: 'GET_SCHREQUEST_NOTIFICATIONS_FAILURE',

    GET_NOTIFICATIONS_REQUEST: 'GET_NOTIFICATIONS_REQUEST',
    GET_NOTIFICATIONS_SUCCESS: 'GET_NOTIFICATIONS_SUCCESS',
    GET_NOTIFICATIONS_FAILURE: 'GET_NOTIFICATIONS_FAILURE',

    GET_NOTIFICATIONS_FOR_FOLLOWED_REQUEST: 'GET_NOTIFICATIONS_FOR_FOLLOWED_REQUEST',
    GET_NOTIFICATIONS_FOR_FOLLOWED_SUCCESS: 'GET_NOTIFICATIONS_FOR_FOLLOWED_SUCCESS',
    GET_NOTIFICATIONS_FOR_FOLLOWED_FAILURE: 'GET_NOTIFICATIONS_FOR_FOLLOWED_FAILURE',

    MARK_NOTIFICATION_AS_READ_REQUEST: 'MARK_NOTIFICATION_AS_READ_REQUEST',
    MARK_NOTIFICATION_AS_READ_SUCCESS: 'MARK_NOTIFICATION_AS_READ_SUCCESS',
    MARK_NOTIFICATION_AS_READ_FAILURE: 'MARK_NOTIFICATION_AS_READ_FAILURE',

    DELETE_NOTIFICATION_REQUEST: 'DELETE_NOTIFICATION_REQUEST',
    DELETE_NOTIFICATION_SUCCESS: 'DELETE_NOTIFICATION_SUCCESS',
    DELETE_NOTIFICATION_FAILURE: 'DELETE_NOTIFICATION_FAILURE',


}
