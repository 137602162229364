import { userConstants, registerConstants } from '_constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? 
{ 
  loggingIn: false, loggedIn: true, user 
} : {loggingIn: false, loggedIn: false,};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true,
        user: action.user,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        loggedIn: true,
        user: action.user,
      };
    case registerConstants.REGISTER_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        user: action.user,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        loggingIn: false,
      };
    // Logout
    case userConstants.LOGOUT_REQUEST:
      return {
      };
    case userConstants.LOGOUT_SUCCESS:
      return {
        loggedIn: false,
        user: {},
      };
    case userConstants.LOGOUT_FAILURE:
      return {
      };
      
    // Registed User Password
    case userConstants.REGISTERED_USER_REQUEST:
      return {
        registeredUserRequest: true
      };
    case userConstants.REGISTERED_USER_SUCCESS:
      return {
        registeredUserRequest: false,
        registeredUser: false
      };
    case userConstants.REGISTERED_USER_FAILURE:
      return {
        registeredUserRequest: false,
        registeredUser: false
      };

      // Reset Password
    case userConstants.RESET_PASSWORD_REQUEST:
      return {
        resetPasswordRequest: true,
    };
    case userConstants.RESET_PASSWORD_SUCCESS:
      return {
        resetPasswordRequest: false,
        loggedIn: false,
        user: {},
      };
    case userConstants.RESET_PASSWORD_FAILURE:
      return {
        resetPasswordRequest: false,
      };


    // FaceBook Authentication Token
    case userConstants.GET_FACEBOOK_ACCESS_TOKEN_REQUEST:
      return {
        gotFBAccessToken: false,
        gotFBAccessTokenError: false,
      };
    case userConstants.GET_FACEBOOK_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        gotFBAccessToken: true,
        gotFBAccessTokenError: false,
        fbAccToken: action.data.token.access_token,
        phone: action.data.info.phone === undefined ? state.phone : action.data.info.phone.number,
        email: action.data.info.email === undefined ? state.email : action.data.info.email.address,
      };
    case userConstants.GET_FACEBOOK_ACCESS_TOKEN_FAILURE:
      return {
        gotFBAccessToken: true,
        gotFBAccessTokenError: true,
        errorMessage: action.error,
      }
    // LinkedIn Authentication Token
    case userConstants.GET_LINKEDIN_ACCESS_TOKEN_REQUEST:
      return {
        ...state,
        gotLinkedInAccessToken: false,
        gotLinkedInAccessTokenError: false,
      };
    case userConstants.GET_LINKEDIN_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        gotLinkedInAccessToken: true,
        gotLinkedInAccessTokenError: false,
        linkedInAccToken: action.data.token.access_token,
        linkedInCompanies: action.data.info.elements
      };
    case userConstants.GET_LINKEDIN_ACCESS_TOKEN_FAILURE:
      return {
        ...state,
        gotLinkedInAccessToken: true,
        gotLinkedInAccessTokenError: true,
        errorMessage: action.error,
      }
     // Apple Authentication Token
     case userConstants.GET_APPLE_ACCESS_TOKEN_REQUEST:
      return {
        ...state,
        gotAppleAccessToken: false,
        gotAppleAccessTokenError: false,
      };
    case userConstants.GET_APPLE_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        gotAppleAccessToken: true,
        gotAppleAccessTokenError: false,
        appleAccToken: action.data.access_token,
        appleInfo: action.data.info,

      };
    case userConstants.GET_APPLE_ACCESS_TOKEN_FAILURE:
      return {
        ...state,
        gotAppleAccessToken: true,
        gotAppleAccessTokenError: true,
        errorMessage: action.error,
      }
    // Yelp Data
    case userConstants.GET_YELP_DATA_REQUEST:
      return {
        ...state,
        gotYelpData: false,
        gotYelpDataError: false,
      };
    case userConstants.GET_YELP_DATA_SUCCESS:
      return {
        ...state,
        gotYelpData: true,
        gotYelpDataError: false,
        yelpData: action.data.yelp,
        yelpDatas: action.data.yelps,
        // name: action.data.yelp.name,
        // phone: action.data.yelp.phone,
      };
    case userConstants.GET_YELP_DATA_FAILURE:
      return {
        ...state,
        gotYelpData: true,
        gotYelpDataError: true,
        errorMessage: action.error,
      }
    case userConstants.SELECTED_YELP_DATA_MAP_UPDATED:
      return {
        ...state,
        selectedYelpDataChanged: false, 
      };
    case userConstants.SET_SELECTED_YELP_DATA:
      return {
        ...state,
        selectedYelpData: action.yelpData,
        selectedYelpDataChanged: true, 
      }

    // Terms Accepted
    case userConstants.TERMS_ACCEPTED_REQUEST:
    return {
      ...state,
    };
  case userConstants.TERMS_ACCEPTED_SUCCESS:
    return {
      ...state,
    };
  case userConstants.TERMS_ACCEPTED_FAILURE:
    return {
      ...state,
    };

    default:
      return state;
  }
}