import { eventConstants } from '_constants';
import { userService } from '_services';
import { alertActions, userActions } from '_actions';
// import { history } from '_helpers';
import * as moment from 'moment';

export const eventActions = {
    getEvents,
    getNearbyEventsForSpecialist,
    createUpdateEvent,
    createUpdateSubevent,
    deleteSubevent,
    findSpecialistsForSubevent,
    bidBookSpecialist,
    deleteBid,
    deleteEvent,
    findAvailability,
    getEventsForUser,
    updateBidState,
    getGuestList,
    eventUpdateNotify,
};

//function getEvents(eventId = null, pos = null, callback = null) {
function getEvents(args={}, callback = null) {
    const user = JSON.parse(localStorage.getItem('user'));
    console.log("eventActions: getEvents()")
    console.log(user);
    const userIsSpecialist = user && user.is_specialist;
    const userIsBusiness = user && user.is_business;
    console.log(user && user.session);
    console.log(user && user.is_business);
    
    const forceUserEventsQuery = args.hasOwnProperty("forceUserEventsQuery") ? true : false 

    const event_query = forceUserEventsQuery ? 'user_events_query' : userIsSpecialist && (args.hasOwnProperty('schId') || args.hasOwnProperty('bidState') || args.hasOwnProperty('bidId') || args.hasOwnProperty('messageEvents')  ) ? 'specialist_event_query' :
                        userIsBusiness   ? 'business_events_query' : 'user_events_query';
    console.log("What is the event query", event_query);
    const params = {
        request: event_query,
      };
    if (args.hasOwnProperty('eventId')) {
        params['event_id'] = args.eventId
    }
    if (args.hasOwnProperty('pos')) {
        params['latitude'] = args.pos.lat
        params['longitude'] = args.pos.lng
        if (args.pos.radius !== null) {
            params['distance'] = args.pos.radius
        }    
    }
    if (args.hasOwnProperty('bidState')) {    
        params[args.bidState] = 1  // bidState one of 'bid', 'booked', 'notify'
    }
    if (args.hasOwnProperty('startDate') && args.hasOwnProperty('endDate')) {
        params['start_date'] = args.startDate.format('YYYY-MM-DD');
        params['end_date'] = args.endDate.format('YYYY-MM-DD');
    }
    if (args.hasOwnProperty('schId')) {
        params['scheduling_calendar_id'] = args.schId
    }
    if (args.hasOwnProperty('bidId')) {
        params['bid_id'] = args.bidId
    }
    if (args.hasOwnProperty('messageEvents')) {
        console.log("GET NEW MESSAGE VENTS")
        params['message_events'] = 1;
    }
    if (args.hasOwnProperty('forTraits')) {
	params['for_traits'] = args.forTraits
    }

    return dispatch => {
        dispatch(request());
        userService.userRequest(params)      
        .then(
            data => {
                if (data.success) {
                    console.log("Got Events", data);
                    if (event_query === 'business_events_query') {
                        // convert map to array of events
                        let events = Object.keys(data.events).map( key => {
                            // prevent overriding event_id if this call is made by business for an
                            // event not of its own
                            if (!data.events[key].hasOwnProperty('event_id')) {
                                data.events[key]['event_id'] = parseInt(key, 10);
                            }
                            return data.events[key];
                        }).sort( (left, right) => {
                            return moment.utc(left.ev_start_time).diff(moment.utc(right.ev_start_time))
                        });
                        let sp_info = data.sp_info;
                        let notifications = data.notifications;
                        let new_messages = data.new_messages;
                        console.log("success_bus", args.eventId, events, sp_info, notifications, new_messages)
                        dispatch(success_bus(args.eventId, events, sp_info, notifications, new_messages));
                        if (callback) {
                            callback(true, events)
                        }
                    } else {
                        // let events = []
                        // if (userIsSpecialist) {
                        //     if (data.hasOwnProperty("booked")) {
                        //         events = data.booked
                        //     }
                        //     if (data.hasOwnProperty("bid")) {
                        //         events = data.bid
                        //     }
                        // } else {
                        //     events = data.events
                        // }
                        console.log("SPC EVENTS QUERY", data)
                        let events = (event_query === 'specialist_event_query') ? (data.hasOwnProperty("booked") ? data.booked : 
                                                                                   data.hasOwnProperty("bid")    ? data.bid :
                                                                                   data.hasOwnProperty("notify") ? data.notify : data.message_events) : 
                                                                                  data.events
                            .sort( (left, right) => {
                                return moment.utc(left.ev_start_time).diff(moment.utc(right.ev_start_time))
                            });
                        if (event_query === 'specialist_event_query') {
                            if (data.hasOwnProperty("booked") ) {
                                dispatch(success_spc_booked(events));

                            } else if (data.hasOwnProperty("bid") ) {
                                dispatch(success_spc_bid(events));

                            } else if (data.hasOwnProperty("notify") ) {
                                dispatch(success_spc_notify(events));

                            }
                            if (args.hasOwnProperty("bidId")) {
                                let event = events && events.length > 0 && events[0]
                                dispatch(success_spc_event(event))
                            }
                            dispatch(success_spc(events, args.hasOwnProperty('messageEvents')))
                        } else {
                            dispatch(success(events));
                        }
                        if (callback) {
                            callback(true, events)
                        }
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) callback(false, msg)
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getEvents: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };
    
    function request()       { return { type: eventConstants.GET_EVENTS_REQUEST} }
    function success(events)   { return { type: eventConstants.GET_EVENTS_SUCCESS, events } }

    function success_bus(eventId, events, sp_info, notifications, new_messages) {
        return { 
            type: eventId == null ? eventConstants.GET_BUS_EVENTS_SUCCESS : eventConstants.GET_BUS_EVENT_SUCCESS,
            events, sp_info, notifications, new_messages 
        }
    }
    function success_spc(events, messageEvents) { return {
        type: messageEvents ? eventConstants.GET_SPC_MESSAGE_EVENTS_SUCCESS : eventConstants.GET_SPC_EVENTS_SUCCESS,
        events
        } 
    }

    function success_spc_booked(events)  { return { type: eventConstants.GET_SPC_BOOKED_EVENTS_SUCCESS, events } }
    function success_spc_bid(events)  { return { type: eventConstants.GET_SPC_BID_EVENTS_SUCCESS, events } }
    function success_spc_notify(events)  { return { type: eventConstants.GET_SPC_NOTIFY_EVENTS_SUCCESS, events } }
    function success_spc_event(event)  { return { type: eventConstants.GET_SPC_EVENT_SUCCESS, event } }

    function failure(error)  { return { type: eventConstants.GET_EVENTS_FAILURE, error } }
}

function getNearbyEventsForSpecialist(args={}, callback = null) {
    const event_query = 'user_events_query';
    const params = {
        request: event_query,
      };

    if (args.hasOwnProperty('forTraits')) {
	params['for_traits'] = args.forTraits.join(",")
    }
    
    if (args.hasOwnProperty('pos')) {
        params['latitude'] = args.pos.lat
        params['longitude'] = args.pos.lng
        if (args.pos.radius !== null) {
            params['distance'] = args.pos.radius
        }    
    }

    console.log("getNearbyEventsForSpecialist", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)      
        .then(
            data => {
                console.log("getNearbyEventsForSpecialist DATA", data)
                if (data.success) {
                    dispatch(success(data));
                    if (callback !== null) {
                        callback(true, data.events)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("eventUpdateNotify: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };
    
    function request()       { return { type: eventConstants.GET_NEARBY_EVENTS_FOR_SPECIALIST_REQUEST} }
    function success(data)       { return { type: eventConstants.GET_NEARBY_EVENTS_FOR_SPECIALIST_SUCCESS, data } }
    function failure(error)  { return { type: eventConstants.GET_NEARBY_EVENTS_FOR_SPECIALIST_FAILURE, error } }
}


function createUpdateEvent(eventName, description, 
    address, lat, lng, subAdminArea, country,
    startTime, endTime, privateEvent, evPassword, eventId, 
    image, userSelectedImage, 
    create, callback = null) {

    const params = {
        request: 'business_create_update_event',
        create: create ? "1" : "0",
        event_name: eventName,
        address: address,
        sub_administrative_area: subAdminArea,
        country: country,
        latitude: lat,
        longitude: lng,
        start_time: startTime,
        end_time: endTime,
        description: description,
        image: image,
        upload_image: userSelectedImage ? "1" : "0",
        private: privateEvent ? "1" : "0",
      };
    if (eventId != null) {
        params['event_id'] = eventId
    }
    if (evPassword != null) {
        params['ev_password'] = evPassword
    }
    console.log("createUpdateEvent", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    dispatch(success());
                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) callback(false, msg)
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("createUpdateEvent: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: eventConstants.CREATE_UPDATE_EVENT_REQUEST} }
    function success()       { return { type: eventConstants.CREATE_UPDATE_EVENT_SUCCESS } }
    function failure(error)  { return { type: eventConstants.CREATE_UPDATE_EVENT_FAILURE, error } }
}

function deleteEvent(eventId, callback = null) {
    const params = {
        request: 'business_delete_event',
        event_id: eventId,
    }
    console.log("deleteEvent", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    dispatch(success(eventId));
                    if (callback !== null) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) callback(false, msg)
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("deleteEvent: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: eventConstants.DELETE_EVENT_REQUEST} }
    function success(eventId)       { return { type: eventConstants.DELETE_EVENT_SUCCESS, eventId } }
    function failure(error)  { return { type: eventConstants.DELETE_EVENT_FAILURE, error } }    
}

function createUpdateSubevent(sbEvent, eventId, create, callback = null) {

    const params = {
        request: 'business_create_update_sub_event',
        create: create ? 1 : 0,
        event_id: eventId,
        start_time: sbEvent.sb_start_time,
        end_time: sbEvent.sb_end_time,
        notes: sbEvent.notes || '',
        traits_num: sbEvent.traits_num ? JSON.stringify(sbEvent.traits_num) : ''
      };

    if (sbEvent.sb_event_id != null) {
        params['sb_event_id'] = sbEvent.sb_event_id
    }

    console.log("createUpdateSubevent", params)

    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    dispatch(success());
                    if (callback !== null) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) callback(false, msg)
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("createUpdateSubevent: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )        
    };

    function request()       { return { type: eventConstants.CREATE_UPDATE_SUBEVENT_REQUEST } }
    function success()       { return { type: eventConstants.CREATE_UPDATE_SUBEVENT_SUCCESS } }
    function failure(error)  { return { type: eventConstants.CREATE_UPDATE_SUBEVENT_FAILURE, error } }    
}

function deleteSubevent(eventId, sbEventID, callback = null) {
    const params = {
        request: 'business_delete_sub_event',
        sb_event_id: sbEventID,
    }
    console.log("deleteSubevent", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    dispatch(success());
                    dispatch(getEvents(eventId))
                    if (callback !== null) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) callback(false, msg)
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("deleteSubevent: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: eventConstants.DELETE_SUBEVENT_REQUEST} }
    function success()       { return { type: eventConstants.DELETE_SUBEVENT_SUCCESS } }
    function failure(error)  { return { type: eventConstants.DELETE_SUBEVENT_FAILURE, error } }    
}

function findSpecialistsForSubevent(sbEventId, trait=null) {
    const params = {
        request: 'business_find_specialists_for_sub_event',
        // request: 'business_find_all_specialists_for_sub_event_near_event_location',
        sb_event_id: sbEventId,
    }
    if (trait != null) {
        params['trait'] = trait
    }
    console.log("findCelebrityForSubevent", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("business_find_specialists_for_sub_event", data)
                    dispatch(success(sbEventId, data.specialist, data.specialist_nosch));
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("findSpecialistsForSubevent: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )        
    };

    function request()       { return { type: eventConstants.FIND_SPECIALIST_FOR_SUBEVENT_REQUEST} }
    function success(sbEventId, specialist, specialist_nosch) { return { type: eventConstants.FIND_SPECIALIST_FOR_SUBEVENT_SUCCESS, sbEventId, specialist, specialist_nosch } }
    function failure(error)  { return { type: eventConstants.FIND_SPECIALIST_FOR_SUBEVENT_FAILURE, error } }    
}

function bidBookSpecialist(sbEventId, spId, schId, spTraitId,
                           price, action, trait, bidId = null, eventId = null, callback = null) {
    const params = {
        request: 'bid_book_candidate',
        sub_event_id: sbEventId,
        sp_id: spId,
        sch_id: schId,
        sp_trait_id: spTraitId,
        price: price,
        type: action,
    }
    if (bidId != null) {
        params['bid_id'] = bidId
    }
    console.log("bidBookSpecialist", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    dispatch(success());
                    if (bidId == null) {
                        // Not updating bid, refresh page
                        dispatch(findSpecialistsForSubevent(sbEventId, trait))
                    } else {
                        dispatch(getEvents(eventId))
                    }
                    if (callback) {
                        callback(true, data.message)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("bidBookSpecialist: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: eventConstants.BID_BOOK_REQUEST} }
    function success()       { return { type: eventConstants.BID_BOOK_SUCCESS } }
    function failure(error)  { return { type: eventConstants.BID_BOOK_FAILURE, error } }    
}

function deleteBid(eventId, bidId, callback=null) {
    const params = {
        request: 'business_delete_bid',
        bid_id: bidId,
    }
    console.log("deleteBid", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    dispatch(success(bidId));
                    dispatch(getEvents(eventId))
                    if (callback !== null) {
                        console.log("Callback delete bid", data)
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("deleteBid: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: eventConstants.DELETE_BID_REQUEST} }
    function success(bidId)  { return { type: eventConstants.DELETE_BID_SUCCESS, bidId } }
    function failure(error)  { return { type: eventConstants.DELETE_BID_FAILURE, error } }    
}

function findAvailability(options) {
    const params = {
        request: 'business_find_availablity',        
    }
    if (options.hasOwnProperty('date')) { params['date'] = options.date.format('YYYY-MM-DD')} 
    if (options.hasOwnProperty('sp_id')) { params['sp_id'] = options.sp_id} 
    if (options.hasOwnProperty('latitude') && options.hasOwnProperty('longitude')) {
        params['latitude'] = options.latitude
        params['longitude'] = options.longitude
    }
    console.log("findAvailability", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    dispatch(success(data));
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("findAvailability: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: eventConstants.FIND_AVAILABILITY_REQUEST} }
    function success(data)   { return { type: eventConstants.FIND_AVAILABILITY_SUCCESS, data } }
    function failure(error)  { return { type: eventConstants.FIND_AVAILABILITY_FAILURE, error } }    

}

function getEventsForUser(id, is_bus_id, callback = null) {
    const params = {
        request: 'events_query_for_user',
      }
    if (is_bus_id === true) {
        params['business_id'] = id;
    } else {
        params['specialist_id'] = id;
    }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    let events = Object.keys(data.events).map( key => {
                        // prevent overriding event_id if this call is made by business for an
                        // event not of its own
                        if (!data.events[key].hasOwnProperty('event_id')) {
                            data.events[key]['event_id'] = parseInt(key, 10);
                        }
                        return data.events[key];
                    }).sort( (left, right) => {
                        return moment.utc(left.ev_start_time).diff(moment.utc(right.ev_start_time))
                    });
                    dispatch(success(events));
                    if (callback) { callback(events) }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getEventsForUser: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }

    function request()      { return { type: eventConstants.GET_EVENTS_FOR_USER_REQUEST} }
    function success(events)  { return { type: eventConstants.GET_EVENTS_FOR_USER_SUCCESS, events } }
    function failure(error) { return { type: eventConstants.GET_EVENTS_FOR_USER_FAILURE, error } }
}

function updateBidState(newBidState, bidId, eventId, callback = null) {
    const params = {
        request: 'specialist_update_bid',
        approve_bid: newBidState,
        bid_id: bidId,
    }
    console.log("updateBidState", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    dispatch(success());
                    // dispatch(getEvents(eventId))
                    if (callback !== null) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("updateBidState: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: eventConstants.UPDATE_BID_STATE_REQUEST} }
    function success()       { return { type: eventConstants.UPDATE_BID_STATE_SUCCESS } }
    function failure(error)  { return { type: eventConstants.UPDATE_BID_STATE_FAILURE, error } }    
}

function getGuestList(eventId, callback = null) {
    const params = {
        request: 'get_guest_list',
        event_id: eventId,
    }
    console.log("getGuestList", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    dispatch(success(data));
                    // dispatch(getEvents(eventId))
                    if (callback !== null) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getGuestList: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: eventConstants.GET_GUEST_LIST_REQUEST} }
    function success(data)   { return { type: eventConstants.GET_GUEST_LIST_SUCCESS, data } }
    function failure(error)  { return { type: eventConstants.GET_GUEST_LIST_FAILURE, error } }    
}

function eventUpdateNotify(eventId, callback = null) {
    const params = {
        request: 'business_event_update_notify',
        event_id: eventId,
    }
    console.log("eventUpdateNotify", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    dispatch(success(data));
                    if (callback !== null) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("eventUpdateNotify: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: eventConstants.EVENT_UPDATE_NOTIFY_REQUEST} }
    function success(data)   { return { type: eventConstants.EVENT_UPDATE_NOTIFY_SUCCESS, data } }
    function failure(error)  { return { type: eventConstants.EVENT_UPDATE_NOTIFY_FAILURE, error } }    
}