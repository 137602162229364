import React from "react";
// used for making the prop types of this component
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// import { Button } from "components";
import SweetAlert from "components/react-bootstrap-sweetalert/SweetAlert.jsx";
import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";
import {  numberFunction, history } from '_helpers';
import * as moment from 'moment';
import Linkify from 'react-linkify';

import { postsActions } from '_actions';

import { 
    Card,
    CardHeader, 
    CardBody,
    Collapse
  } from "reactstrap";

  import {
     CommentsSection, 
  } from "components";
  

class Post extends React.Component {
  constructor(props) {
    super(props);
    console.log("ImageUpload", props)
    this.state = {
      file: null,
      imagePreviewUrl: this.props.avatar   ? defaultAvatar : 
                       this.props.imageUrl ? this.props.imageUrl : 
                                             defaultImage,
      post: this.props.post ? this.props.post : null,
      index: props.index !== undefined ? props.index : null,
      noShadow: this.props.noShadow ? this.props.noShadow : false, 
      createCommentModal: false, 
      updateCommentModal: false, 
      replyCommentModal: false,
      new_comment: '',
      update_comment: '',
      selectedCommentId: '',
      new_reply: '',
      reply_name: '',
      reply_id: '',
      commentsOpen: false,
      alert: null,
      openedCollapses: this.props.defaultOpened !== undefined ?  ["collapseOne"] : [],

    };
    this.toggleCreateCommentModal = this.toggleCreateCommentModal.bind(this);
    this.toggleReplyCommentModal = this.toggleReplyCommentModal.bind(this);
    this.toggleUpdateCommentModal = this.toggleUpdateCommentModal.bind(this);
    this.deleteCommentAlert = this.deleteCommentAlert.bind(this);
    this.deletePostAlert = this.deletePostAlert.bind(this);
    this.hideAlert = this.hideAlert.bind(this);

    this.toggleComments = this.toggleComments.bind(this);
    this.replyComment = this.replyComment.bind(this);
    this.createComment = this.createComment.bind(this);
    this.likeComment = this.likeComment.bind(this);
    this.updateComment = this.updateComment.bind(this);
    this.deleteComment = this.deleteComment.bind(this);
    this.isCommentOwner = this.isCommentOwner.bind(this);
    this.goToPostPage = this.goToPostPage.bind(this)
  }

  collapsesToggle = collapse => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: []
      });
    } else {
      this.setState({
        openedCollapses: [collapse]
      });
    }
  };

  toggleCreateCommentModal() {
    this.setState({
      createCommentModal: !this.state.createCommentModal
    });
  }

  toggleReplyCommentModal(comment) {
    this.setState({
      replyCommentModal: !this.state.replyCommentModal,
      reply_id: comment.id,
      reply_name: comment.name
    });
  }

  toggleUpdateCommentModal(existingComment) {
    this.setState({
      updateCommentModal: !this.state.updateCommentModal,
      update_comment: existingComment.comment,
      selectedCommentId: existingComment.id
    });
  }

  toggleComments() {
    this.setState({
      commentsOpen: !this.state.commentsOpen
    });
  }

  

  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }


  handleChange(e) {
    console.log("handleChange", e.target.name, e.target.value)
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  goToPostPage(post) {
    const by_id = post.comments[0].by_id
    history.push(`/dash/${numberFunction(by_id)}/postsPage/${numberFunction(post.post_id)}`)
  }


createComment() {
  const {post, new_comment } = this.state
  if (new_comment !== '' ) {
    const args = {
      // image: this.state.post_image,
      post_id: post.post_id,
      comment: new_comment,
      // mediaType: mediaType
    }
    this.props.dispatch(postsActions.createComment(args, post.comments[0].by_id))
  }
}

replyComment() {
  const {post, new_reply, reply_id } = this.state
  if (new_reply !== '' ) {
    const args = {
      // image: this.state.post_image,
      post_id: post.post_id,
      comment: new_reply,
      reply_id: reply_id,
      // mediaType: mediaType
    }
    this.props.dispatch(postsActions.createComment(args, post.comments[0].by_id))
  }
}



likeComment(commentId, like ) {

  const {post} = this.state
  this.props.dispatch(postsActions.likeComment(commentId, like, post.comments[0].by_id))
  
}

updateComment() {

  const {post, update_comment, selectedCommentId } = this.state
  if (update_comment !== '' ) {
    const args = {
      // image: this.state.post_image,
      comment_id: selectedCommentId,
      comment: update_comment,
      // mediaType: mediaType
    }
    this.props.dispatch(postsActions.updateComment(args, post.comments[0].by_id))
  }
  
}

deletePostAlert() {
  this.setState({
    alert: (
      <SweetAlert
        danger
        title="Delete Post?"
        onConfirm={() => this.deletePost() }
        onCancel={() => this.hideAlert()}
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="default"
        confirmBtnText="Delete"
        cancelBtnText="Cancel"
        showCancel
      >
        <p> Are you sure you want to delete your post?</p>
      </SweetAlert>
    )
  });
}



deleteCommentAlert(comment) {
  this.setState({
    alert: (
      <SweetAlert
        danger
        title="Delete Comment?"
        onConfirm={() => this.deleteComment(comment.id) }
        onCancel={() => this.hideAlert()}
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="default"
        confirmBtnText="Delete"
        cancelBtnText="Cancel"
        showCancel
      >
        <p> Are you sure you want to delete <h4 className="text-primary">{comment.name}'s</h4>&nbsp;comment?</p>
      </SweetAlert>
    )
  });
}

hideAlert() {
  this.setState({
    alert: null
  });
}

deletePost() {

  const {post} = this.state
  this.props.dispatch(postsActions.deletePost(post.post_id))
  this.hideAlert();

}

deleteComment(commentId) {

  const {post} = this.state
  this.props.dispatch(postsActions.deleteComment(commentId, post.comments[0].by_id))
  this.hideAlert();

}

isCommentOwner(id) {
  const user = JSON.parse(localStorage.getItem('user')); 
  const isCommentOwner = user && user.user_id === id
  return isCommentOwner
}

isPostOwner() {
  const ownerId = this.state.post.comments[0].by_id
  const user = JSON.parse(localStorage.getItem('user')); 
  const isPostOwner = user && user.user_id === ownerId
  return isPostOwner
}


  render() {
    const { post, noShadow } = this.state
    // const { info } = this.props
    // const user = JSON.parse(localStorage.getItem('user')); 
    // const loggedIn = user && user.hasOwnProperty('email');
    // const userId =  user && user.user_id
    console.log("Post.jsx", this.state)
    // const mediaExt = this.state.imagePreviewUrl.split('.').pop()
    // const isVideo = mediaExt === 'mov' || mediaExt === 'mp4' || mediaExt === 'ogg' || mediaExt === 'webm';
    // const isPhoto = mediaExt === 'jpg' || mediaExt === 'jpeg' || mediaExt === 'png';
    // const isYoutube =  !isPhoto &&!isVideo && !this.state.imagePreviewUrl.includes('http') && this.state.imagePreviewUrl.length > 0
    // console.log("isVideo", isVideo, mediaExt)
    return (
    <div>
        {this.state.alert}
        <Card style={noShadow ? {paddingBottom:'12px', boxShadow: "0 0 0rem #fff"} : {paddingBottom:'12px'}}>
            <CardHeader className="d-flex align-items-center remove-after cursor-regular">
              <div className="d-flex align-items-center">
                  <img
                    alt={post.comments[0].photo}
                    className="avatar"
                    src={post.comments[0].photo}
                  />
                <div className="mx-3">
                  {post.comments[0].is_specialist && 
                        <Link className="font-weight-600 text-sm" to={ { pathname: `/dash/c_profile/${numberFunction(post.comments[0].by_id)}`, 
                          state: {sp_id: post.comments[0].by_id, }} }>
                          {post.comments[0].name}
                        </Link>
                        }
                  {post.comments[0].is_business && 
                  <Link className="font-weight-600 text-sm" to={ { pathname: `/dash/b_profile/${numberFunction(post.comments[0].by_id)}`, 
                    state: {business_id: post.comments[0].by_id, }} }>
                      {post.comments[0].name}
                  </Link>
                  }
                  <small className="d-block text-muted">{moment(post.comments[0].modified).utc().fromNow()}</small>
                </div>
              </div>
              {/* <div className="text-right ml-auto">
              {this.isCommentOwner(post.comments[0].by_id) && 
                <UncontrolledDropdown>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                      <DropdownToggle
                          className="fas fa-ellipsis-h"
                          size="sm"                        
                          color="default">
                        <i className="now-ui-icons loader_gear" />
                      </DropdownToggle>
                      <DropdownMenu right>
                        {
                        <DropdownItem className="text" onClick={() => this.toggleUpdateCommentModal(post.comments[0])} >
                        Update Post
                        </DropdownItem>}
                        {this.isCommentOwner(post.comments[0].by_id) && 
                        <DropdownItem className="text-danger" onClick={() => this.deletePostAlert()} >
                        Delete Post
                        </DropdownItem>}
                      </DropdownMenu>
                  </UncontrolledDropdown>
                    }
              </div> */}
            </CardHeader>
            <CardBody style={{paddingBottom:'0px'}}>
              <Collapse
                role="tabpanel"
                  isOpen={this.state.openedCollapses.includes(`collapse_bio_${post.id}`)}
                >
                  <Linkify properties={{target: '_blank'}}> 
                    {/* <p style={{marginTop:'10px'}} className="line-styling font-weight-light"> */}
                      <p className="mb-4 line-styling">
                        {post.comments[0].comment}                    
                      </p>
                    {/* </p> */}
                  </Linkify>     
                    </Collapse>
                    <Linkify properties={{target: '_blank'}}> 
                    {!this.state.openedCollapses.includes(`collapse_bio_${post.id}` ) && 
                    // <p style={{marginTop:'10px'}} className="line-styling font-weight-light two-line">   
                    <p className="mb-4 line-styling two-line">
                      {post.comments[0].comment}                    
                    </p>
                    // </p>
                    }
                    </Linkify>
                  <p className="mt-3 mb-3 text-sm" >
                    <span
                      className="text-nowrap font-weight-600 cursor-pointer"
                      role="tab"
                      onClick={() => this.collapsesToggle(`collapse_bio_${post.id}`)}
                      aria-expanded={this.state.openedCollapses.includes(
                        `collapse_bio_${post.id}`)}
                      >
                      {this.state.openedCollapses.includes(
                        `collapse_bio_${post.id}`) ? 'See less' : 'See more' }
                    </span>
                  </p>              

              <img
                alt="..."
                className="img-fluid rounded cursor-pointer"
                src={post.comments[0].media_url}
                onClick={( )=> this.goToPostPage(post)}
              />
              <CommentsSection imageUrl={post.comments[0].media_url} post={post} /> 
              {/* <Row className="align-items-center my-3 pb-3 border-bottom">
                <Col sm="6">
                  <div className="icon-actions">
                    <div
                      className= {loggedIn && post.comments[0].likes.filter( like => like.by_id === user.user_id).length > 0 ? "like active" : ""} 
                      href="#pablo"
                      onClick={() => this.likeComment(post.comments[0].id, post.comments[0].likes.filter( like => like.by_id === user.user_id).length > 0 ? 0 : true)} 
                    >
                      <i className="ni ni-like-2"  />
                      <span className="text-muted">{trimLikes(post.comments[0].likes.length)}</span>
                    </div>
                    <div
                        role="tab"                            
                        onClick={() => this.collapsesToggle("collapseOne")}
                        aria-expanded={this.state.openedCollapses.includes(
                          "collapseOne"
                        )}
                      >
                      <i className="ni ni-chat-round" />
                      <span className="text-muted">{post.comments.slice(1).filter(item => item.state !== "deleted").length}</span>
                    </div>
                  </div>
                </Col>
                <Col className="d-none d-sm-block" sm="6">
                </Col>
              </Row>
              <div className="mb-1">
                  <Collapse 
                  role="tabpanel"               
                  isOpen={this.state.openedCollapses.includes("collapseOne")}
                  > 
                    <div>           
                    { post.comments && post.comments.length > 0 && post.comments.slice(1).filter(item => item.state !== "deleted").map( (comment, key) => 
                      <div className="media-comment" key={key}>
                          {comment.is_specialist && 
                            <Link className="text-primary" to={ { pathname: `/dash/c_profile/${numberFunction(comment.by_id)}`, 
                            state: {sp_id: comment.by_id, }} }>
                              <img
                                alt="..."
                                className="avatar avatar-lg media-comment-avatar rounded-circle"
                                src={comment.photo}
                                style={comment.hasOwnProperty("reply_id") ? { transform:"translateX(25px"}  : {}}
                              />
                              </Link>
                              }
                            {comment.is_business && 
                            <Link className="text-primary" to={ { pathname: `/dash/b_profile/${numberFunction(comment.by_id)}`, 
                              state: {business_id: comment.by_id, }} }>
                              <img
                              alt="..."
                              className="avatar avatar-lg media-comment-avatar rounded-circle"
                              src={comment.photo}
                              style={comment.hasOwnProperty("reply_id") ? { transform:"translateX(25px"}  : {}}

                              />
                            </Link>
                              }
                            {!comment.is_business && !comment.is_specialist && !comment.isAgency && 
                              <img
                              alt="..."
                              className="avatar avatar-lg media-comment-avatar rounded-circle"
                              src={comment.photo}
                              style={comment.hasOwnProperty("reply_id") ? { transform:"translateX(25px"}  : {}}
                              />
    
                              }
                        <div style={comment.hasOwnProperty("reply_id") ? {paddingLeft:"50px", transform:"translateY(-42px"}  : {paddingLeft:"25px", transform:"translateY(-42px"}}>
                          <div className="media-comment-text">
                            {comment.is_specialist && 
                              <Link className="text-primary" to={ { pathname: `/dash/c_profile/${numberFunction(comment.by_id)}`, 
                                state: {sp_id: comment.by_id, }} }>
                                <h6 className="h5 mt-0">{comment.name}</h6>
                              </Link>
                              }
                              {comment.is_business && 
                              <Link className="text-primary" to={ { pathname: `/dash/b_profile/${numberFunction(comment.by_id)}`, 
                                state: {business_id: comment.by_id, }} }>
                                <h6 className="h5 mt-0">{comment.name}</h6>
                              </Link>
                              }
                              {!comment.is_business && !comment.is_specialist && !comment.isAgency && 
                              <h6 className="h5 mt-0">{comment.name}</h6>

                              }
                            <p className="text-sm lh-160">{comment.comment}</p>
                            <div className="icon-actions">
                              <div
                                className={loggedIn && comment.likes.filter( like => like.by_id === user.user_id).length > 0 ? "like active" : ""}
                                onClick={() => this.likeComment(comment.id, loggedIn && comment.likes.filter( like => like.by_id === user.user_id).length > 0 ? 0 : true)}
                                >
                                <i className="ni ni-like-2" />
                                <span className="text-muted">{comment.likes.length === 0 ? `` : comment.likes.length === 1 ? `${comment.likes.length} like`: `${comment.likes.length} likes`}</span>
                              </div>
                              {this.isCommentOwner(comment.by_id) &&
                              <div onClick={() => this.toggleUpdateCommentModal(comment)}>
                                <i className="fas fa-edit" />
                                <span className="text-muted">Update</span>
                              </div>
                              }
                              {loggedIn && 
                              <div  onClick={() => this.toggleReplyCommentModal(comment)}>
                                <i className="fas fa-reply" />
                                <span className="text-muted">Reply</span>
                              </div>
                              }
                              { (this.isCommentOwner(comment.by_id) || this.isPostOwner() ) &&  
                              <div onClick={() => this.deleteCommentAlert(comment)}>
                                <i className="fas fa-trash-alt text-danger" />
                                <span className="text-danger">Delete</span>
                              </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <hr/>
                  </div>
                </Collapse>
                {loggedIn && 
                <Media className="align-items-center" style={{marginTop:'24px'}}>
                  <img
                    alt="..."
                    className="avatar avatar-lg rounded-circle mr-4"
                    src={info.image}
                    style={{transform:"translateY(-10px"}}
                  />
                  <Media body>
                      <FormGroup>
                        <InputGroup>
                          <Input
                            type="textarea"
                            name="new_comment"
                            rows="1"
                            placeholder="Enter your comment"
                            value={this.state.new_comment}
                            onChange={e => this.handleChange(e)}
                          />
                        <InputGroupAddon addonType="append">
                          <Button color="primary" outline onClick={() => this.createComment() }>Submit</Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Media>
                </Media>
               }
              </div> */}
            </CardBody>
          </Card>
        {/* <Row>
            <Modal
              isOpen={this.state.createCommentModal}
              toggle={this.toggleCreateCommentModal}
              className="modal-notice text-left"
              size="lg"
              fade={false}
            >
              <ModalHeader toggle={this.createCommentModal}>
                Post
              </ModalHeader>
              <ModalBody>
                <div>
                  <Row>
                 
                    </Row>
                    <br/><br/><hr/><br/><br/>     
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12} >
                        <FormGroup className={this.state.new_comment} >
                            <Input type="textarea" 
                              name="new_comment"
                              placeholder="Enter your comment"
                              rows="3"
                              cols="80"
                              style={{maxHeight:"175px"}}                        
                              value={this.state.new_comment}
                              onChange={e => this.handleChange(e)}
                            />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
              </ModalBody>
                <ModalFooter className="text-center">
                  <Col xs={12} sm={12} md={12} lg={12} className="ml-auto mr-auto">
                    <Button onClick={() => this.createComment() } color="primary" className="title" size={'lg'} >
                        Post Comment
                    </Button>                
                  </Col>
              </ModalFooter>
              
            </Modal>
          </Row>
          <Row>
            <Modal
              isOpen={this.state.replyCommentModal}
              toggle={this.toggleReplyCommentModal}
              className="modal-notice text-left"
              size="lg"
              fade={false}
            >
              <ModalHeader toggle={this.replyCommentModal}>
                Replying to {this.state.reply_name}
              </ModalHeader>
              <ModalBody>
                <div>
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12} >
                        <FormGroup className={this.state.new_reply} >
                            <Input type="textarea" 
                              name="new_reply"
                              placeholder="Enter your reply"
                              rows="3"
                              cols="80"
                              style={{maxHeight:"175px"}}                        
                              value={this.state.new_reply}
                              onChange={e => this.handleChange(e)}
                            />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
              </ModalBody>
                <ModalFooter className="text-center">
                  <Col xs={12} sm={12} md={12} lg={12} className="ml-auto mr-auto">
                    <Button onClick={() => this.replyComment() } color="primary" className="title" size={'lg'} >
                        Reply
                    </Button>                
                  </Col>
              </ModalFooter>
              
            </Modal>
          </Row>
          <Row>
            <Modal
              isOpen={this.state.updateCommentModal}
              toggle={this.toggleUpdateCommentModal}
              className="modal-notice text-left"
              size="lg"
              fade={false}
            >
              <ModalHeader toggle={this.updateCommentModal}>
                Update
              </ModalHeader>
              <ModalBody>
                <div>
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12} >
                        <FormGroup className={this.state.update_comment} >
                            <Input type="textarea" 
                              name="update_comment"
                              placeholder="Enter your comment"
                              rows="3"
                              cols="80"
                              style={{maxHeight:"175px"}}                        
                              value={this.state.update_comment}
                              onChange={e => this.handleChange(e)}
                            />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
              </ModalBody>
                <ModalFooter className="text-center">
                  <Col xs={12} sm={12} md={12} lg={12} className="ml-auto mr-auto">
                    <Button onClick={() => this.updateComment() } color="primary" className="title" size={'lg'} >
                        Save
                    </Button>                
                  </Col>
              </ModalFooter>
              
            </Modal>
          </Row>  */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { postsData, memberInfo } = state;
  const { posts, fetchGetPosts } = postsData;     
  const { fetchingInfo, info, } = memberInfo;

  return {
    posts, fetchGetPosts, fetchingInfo, info,

  };
}

const connectedPost = connect(mapStateToProps)(Post);
export { connectedPost as Post };


