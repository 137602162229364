import { eventConstants } from '_constants';
import * as moment from 'moment';
import { traitStatusForEvents } from '_helpers';

const initialState = {
    createUpdateDone: false,
    fetchingEvents: false,
    deleteSubeventRequest: false,
    findSpecialistRequest: false,
    events: [],
    nearbyEventsForSpecialist: [],
    bookedEvents: [],
    bidEvents: [],
    notifyEvents: [],
    spcEvent: null, 
};

function mergeEvent(events, newEvents) {
    if (events && events.length > 0) {
        console.log("mergeEvent", events, newEvents)
        if (newEvents.length > 0) {
            let newEvent = newEvents[0]
            if (events.filter(event => event.event_id === newEvent.event_id).length > 0) {
                let mergedEvents = events.map( event => event.event_id === newEvent.event_id ? newEvent : event)
                return mergedEvents    
            } else {
                events.push(newEvent)
                return events
            }
        } else {
            return events;
        }
    } else {
        return newEvents
    }
}

function mergeBids(events, newEvents) {
    if (events && events.length > 0) {
        console.log("mergeEvent", events, newEvents)
        if (newEvents && newEvents.length > 0) {
            let mergedEvents = events.reduce ( (acc, event) => {
                if (newEvents.filter( nev => nev.bid_id === event.bid_id).length === 0) {
                    acc.push(event)
                }
                return acc;
            }, [...newEvents])
            return mergedEvents
        } else {
            return events;
        }
    } else {
        return newEvents
    }
}


export function eventsData(state = initialState, action) {
    switch(action.type) {
        case eventConstants.GET_EVENTS_REQUEST:
            return {
                ...state,
                fetchingEvents: true,
            };
        case eventConstants.GET_BUS_EVENTS_SUCCESS:
            return {
                ...state,
                fetchingEvents: false,
                events: action.events,
                sp_info: action.sp_info,
                sbTraitStatus: traitStatusForEvents(state.sbTraitStatus, action.events)
            };
        case eventConstants.GET_BUS_EVENT_SUCCESS:
            return {
                ...state,
                fetchingEvents: false,
                events: mergeEvent(state.events, action.events),
                sp_info: action.sp_info,
                sbTraitStatus: traitStatusForEvents(state.sbTraitStatus, action.events)
            };
        case eventConstants.GET_SPC_EVENTS_SUCCESS:
            return {
                ...state,
                fetchingEvents: false,
                events: mergeBids(state.events, action.events)
                        .sort( (left, right) => {
                            return moment.utc(left.ev_start_time).diff(moment.utc(right.ev_start_time))
                        }),
            };
        case eventConstants.GET_SPC_MESSAGE_EVENTS_SUCCESS:
            return {
                ...state,
                messageEvents: action.events,
            }
        case eventConstants.GET_SPC_BOOKED_EVENTS_SUCCESS:
            return {
                ...state,
                bookedEvents: action.events,
            }
        case eventConstants.GET_SPC_BID_EVENTS_SUCCESS:
            return {
                ...state,
                bidEvents: action.events,
            }
        case eventConstants.GET_SPC_NOTIFY_EVENTS_SUCCESS:
            return {
                ...state,
                notifyEvents: action.events,
            }
        case eventConstants.GET_SPC_EVENT_SUCCESS:
            return {
                ...state,
                spcEvent: action.event,
            }
        case eventConstants.GET_EVENTS_SUCCESS:
            return {
                ...state,
                fetchingEvents: false,
                events: action.events,
            };
        case eventConstants.GET_EVENTS_FAILURE:
            return {
                ...state,
                fetchingEvents: false,
            };
        case eventConstants.GET_NEARBY_EVENTS_FOR_SPECIALIST_REQUEST:
            return {
                ...state,
                getNearbyEventsForSpecialist: false,
            };
        case eventConstants.GET_NEARBY_EVENTS_FOR_SPECIALIST_SUCCESS:
            return {
                ...state,
                getNearbyEventsForSpecialist: true,
                nearbyEventsForSpecialist: action.data.events,
            };
        case eventConstants.GET_NEARBY_EVENTS_FOR_SPECIALIST_FAILURE:
            return {
                ...state,
                getNearbyEventsForSpecialist: true,
                nearbyEventsForSpecialist: null,

            };
        case eventConstants.CREATE_UPDATE_EVENT_REQUEST:
            return {
                ...state,
                createUpdateDone: false,
                createUpdateEvent: true, 
            };
        case eventConstants.CREATE_UPDATE_EVENT_SUCCESS:
            return {
                ...state,
                createUpdateDone: true,
                createUpdateEvent: false, 
            };
        case eventConstants.CREATE_UPDATE_EVENT_FAILURE:
            return {
                ...state,
                createUpdateDone: true,
                createUpdateEvent: false, 
            };
        case eventConstants.DELETE_EVENT_REQUEST:
            return {
                ...state,
                deleteEventRequest: true,
            };
        case eventConstants.DELETE_EVENT_SUCCESS:
            return {
                ...state,
                events: state.events.filter( event => event.event_id !== action.eventId),
                deleteEventRequest: false,
            };
        case eventConstants.DELETE_EVENT_FAILURE:
            return {
                ...state,
                deleteEventRequest: false,
            }; 
        case eventConstants.CREATE_UPDATE_SUBEVENT_REQUEST:
            return {
                ...state,
                createUpdateDone: false,
                createUpdateSubevent: true, 

            };
        case eventConstants.CREATE_UPDATE_SUBEVENT_SUCCESS:
            return {
                ...state,
                createUpdateDone: true,
                createUpdateSubevent: false, 

            };
        case eventConstants.CREATE_UPDATE_SUBEVENT_FAILURE:
            return {
                ...state,
                createUpdateDone: true,
                createUpdateSubevent: false, 
            };
        case eventConstants.DELETE_SUBEVENT_REQUEST:
            return {
                ...state,
                deleteSubeventRequest: true,
            };
        case eventConstants.DELETE_SUBEVENT_SUCCESS:
            return {
                ...state,
                deleteSubeventRequest: false,
            };
        case eventConstants.DELETE_SUBEVENT_FAILURE:
            return {
                ...state,
                deleteSubeventRequest: false,
            };             
        case eventConstants.FIND_SPECIALIST_FOR_SUBEVENT_REQUEST:
            return {
                ...state,
                findSpecialistRequest: true,
                specialists: {},
                specialists_nosch: {}
            };
        case eventConstants.FIND_SPECIALIST_FOR_SUBEVENT_SUCCESS:
            return {
                ...state,
                findSpecialistRequest: false,
                specialists: action.specialist,
                specialists_nosch: action.specialist_nosch,
            };
        case eventConstants.FIND_SPECIALIST_FOR_SUBEVENT_FAILURE:
            return {
                ...state,
                findSpecialistRequest: false,
            };   
        case eventConstants.FIND_AVAILABILITY_REQUEST:
            return {
                ...state,
                findAvailabilityRequest: true,                
            }
        case eventConstants.FIND_AVAILABILITY_SUCCESS:
            return {
                ...state,
                available: action.data.available,
                findAvailabilityRequest: false,
            }
        case eventConstants.FIND_AVAILABILITY_FAILURE:
            return {
                ...state,
                findAvailabilityRequest: false,
            }           

    // GET EVENTS FOR USER
    case eventConstants.GET_EVENTS_FOR_USER_REQUEST:
        return {
            ...state,
            getEventsForUserRequest: true,
        };
    case eventConstants.GET_EVENTS_FOR_USER_SUCCESS:
        return {
            ...state,
            getEventsForUserRequest: false,
            eventsForUser: action.events,
        };
    case eventConstants.GET_EVENTS_FOR_USER_FAILURE:
        return {
            ...state,
            getEventsForUserRequest: false,
        };
    // Update BID
    case eventConstants.UPDATE_BID_STATE_REQUEST:
        return {
            ...state,
            updateBidStateRequest: true,
        };
    case eventConstants.UPDATE_BID_STATE_SUCCESS:
        return {
            ...state,
            updateBidStateRequest: false,
        };
    case eventConstants.UPDATE_BID_STATE_FAILURE:
        return {
            ...state,
            updateBidStateRequest: false,
        }; 
    // Delete BID
    case eventConstants.DELETE_BID_REQUEST:
        return {
            ...state,
            deleteBidRequest: true,            
        };
    case eventConstants.DELETE_BID_SUCCESS:
        return {
            ...state,
            deleteBidRequest: false,
            events: state.events.filter( event => event.bid_d !== action.bidId),
        };
    case eventConstants.DELETE_BID_FAILURE:
        return {
            ...state,
            deleteBidRequest: false,
        };
    //Get Guest List
    case eventConstants.GET_GUEST_LIST_REQUEST:
        return {
            ...state,
            getGuestListRequest: true,
        };
    case eventConstants.GET_GUEST_LIST_SUCCESS:
        return {
            ...state,
            getGuestListRequest: false,
            guests: action.data.guests,
        };
    case eventConstants.GET_GUEST_LIST_FAILURE:
        return {
            ...state,
            getGuestListRequest: false,
        }; 
    //Event Update Notify
    case eventConstants.EVENT_UPDATE_NOTIFY_REQUEST:
        return {
            ...state,
            eventUpdateNotifyRequest: true,
        };
    case eventConstants.EVENT_UPDATE_NOTIFY_SUCCESS:
        return {
            ...state,
            eventUpdateNotifyRequest: false,
            guests: action.data.guests,
        };
    case eventConstants.EVENT_UPDATE_NOTIFY_FAILURE:
        return {
            ...state,
            eventUpdateNotifyRequest: false,
        }; 
    default:
        return state;
    }
}
